<template>
	<v-sheet class="location" id="location" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading || !formValid"
					v-on:click="updateOrCreate()"
				>
					Save
				</v-btn>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<v-form
					ref="locationForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="updateOrCreate"
				>
					<v-row>
						<v-col md="8">
							<perfect-scrollbar
								:options="{ suppressScrollX: true }"
								class="scroll custom-box-top-inner-shadow"
								style="max-height: calc(100vh - 160px); position: relative"
							>
								<Address is-location v-model="location"></Address>
							</perfect-scrollbar>
						</v-col>
						<v-col md="4"> </v-col>
					</v-row>
				</v-form>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateLocation, UpdateLocation, GetLocation } from "@/core/lib/location.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Address from "@/view/components/Address";
import { getCurrentUser } from "@/core/services/jwt.service";

export default {
	name: "location-create",
	title: "Create Location",
	data() {
		return {
			uuid: null,
			barcode: null,
			formValid: true,
			pageLoading: false,
			location: {
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
		};
	},
	components: {
		Address,
	},
	methods: {
		async updateOrCreate() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.locationForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.locationForm.validate()) {
				return false;
			}

			const formData = {
				name: this.location.name,
				id_number: this.location.id_number,
				location: this.location.location,
				latitude: this.location.latitude,
				longitude: this.location.longitude,
				address_line_1: this.location.address_line_1,
				address_line_2: this.location.address_line_2,
				address_city: this.location.address_city,
				address_state: this.location.address_state,
				address_country: this.location.address_country,
				address_postal_code: this.location.address_postal_code,
				description: this.location.description,
				has_lat_long: this.location.has_lat_long,
			};

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					formData.type = this.locationType;
					formData.user = this.locationTypeId;

					const { uuid } = await UpdateLocation(_this.uuid, formData);

					_this.$router.replace({
						name: "location-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Location has been updated." },
					]);
				} else {
					const authUser = getCurrentUser();

					formData.type = "user";
					formData.user = authUser.id;

					const { uuid } = await CreateLocation(formData);

					_this.$router.replace({
						name: "location-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Location has been created." },
					]);
				}
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		getLocation() {
			GetLocation(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.locationType = data.type == 2 ? "user" : "contact";
					this.locationTypeId = data.type == 2 ? data.user : data.contact;
					this.location.name = data.name;
					this.location.id_number = data.id_number;
					this.location.location = data.location;
					this.location.latitude = data.latitude;
					this.location.longitude = data.longitude;
					this.location.address_line_1 = data.address_line_1;
					this.location.address_line_2 = data.address_line_2;
					this.location.address_city = data.address_city;
					this.location.address_state = data.address_state;
					this.location.address_country = data.address_country;
					this.location.address_postal_code = data.address_postal_code;
					this.location.description = data.description;
					this.location.has_lat_long = data.has_lat_long;

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Location", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return this.location.name;
			}
			return "Create new Location";
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Location", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params } = this.$route;
		if (name === "location-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getLocation();
		}
	},
	computed: {
		...mapGetters(["errors"]),
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
